<template>
  <section class="banners__section">
    <div class="banners__controls">
      <button
        class="banners__delete banners__control"
        @click="bannerController('deleteBanner')"
      >
        Удалить
      </button>
      <button
        class="banners__append banners__control"
        @click="bannerController('appendBanner')"
      >
        Добавить
      </button>
      <button
        class="banners__append banners__control"
        @click="bannerController('changeBanner')"
      >
        Редактировать
      </button>
    </div>
    <div class="banners__list">
      <v-radio
        class="banners__item"
        :value="banner.attrs.label"
        :attrs="banner.attrs"
        v-model="active_banner"
        v-for="(banner, idx) in ADMIN_BANNERS"
        :key="idx"
      >
        <template v-slot:append>
          <img
            :src="banner.image"
            alt=""
            class="banners__item-image"
            :ref="banner.value"
          />
        </template>
      </v-radio>
    </div>

    <v-dialog
      class="banners__dialog"
      :data="banners_dialog"
      @adminSave="bannerController('setBanner')"
      @closeDialog="closeDialog"
    >
      <form class="banners__dialog-form">
        <v-input
          v-for="(field, idx) in banner_form.fields.inputs"
          :key="idx"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          class="banners__dialog-input"
        ></v-input>
        <v-radio
          class="banner__dialog-location"
          :value="control.attrs.label"
          :attrs="control.attrs"
          v-model="banner_form.fields.inputs.location.value"
          v-for="(control, idx) in banner_form.fields.locations"
          :key="idx"
        >
        </v-radio>
        <v-radio
          class="banner__dialog-action"
          :value="control.attrs.label"
          :attrs="control.attrs"
          v-model="banner_form.fields.inputs.action.value"
          v-for="(control, idx) in banner_form.fields.actions"
          :key="idx"
        >
        </v-radio>
        <div class="banners__dialog-images">
          <img
            @click="
              bannerController(
                'getBannerLink',
                `${this.$store.state.appURL}/banners/${value}`
              )
            "
            v-for="({ value }, idx) in filtredBannerImages"
            :key="idx"
            :src="`${this.$store.state.appURL}/banners/${value}`"
            alt=""
          />
          {{ value }}
        </div>
        <input
          class="banners__dialog-image"
          type="image"
          :src="banner_form.fields.inputs.promo_picture.value"
          alt=""
        />
      </form>
    </v-dialog>
    <v-dialog
      class="banners__dialog"
      :data="banners_dialog_append"
      @adminSave="bannerController('appendBanner')"
      @closeDialog="closeDialog"
    >
      <form class="banners__dialog-form">
        <v-input
          v-for="(field, idx) in banner_form.fields.inputs"
          :key="idx"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          class="banners__dialog-input"
        ></v-input>
        <v-radio
          class="banner__dialog-location"
          :value="control.attrs.label"
          :attrs="control.attrs"
          v-model="banner_form.fields.inputs.location.value"
          v-for="(control, idx) in banner_form.fields.locations"
          :key="idx"
        >
        </v-radio>
        <v-radio
          class="banner__dialog-action"
          :value="control.attrs.label"
          :attrs="control.attrs"
          v-model="banner_form.fields.inputs.action.value"
          v-for="(control, idx) in banner_form.fields.actions"
          :key="idx"
        >
        </v-radio>
        <input
          class="banners__dialog-image"
          type="image"
          :src="banner_form.fields.inputs.promo_picture.value"
          alt=""
        />
        <div class="banners__dialog-images">
          <img
            @click="
              bannerController(
                'getBannerLink',
                `${this.$store.state.appURL}/banners/${value}`
              )
            "
            v-for="({ value }, idx) in filtredBannerImages"
            :key="idx"
            :src="`${this.$store.state.appURL}/banners/${value}`"
            alt=""
          />
          {{ value }}
        </div>
      </form>
    </v-dialog>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { fields } from "../../helpers/banners_dialog_data";
export default {
  name: "BannersViews",
  data() {
    return {
      active_banner: "",
      banner_form: {
        fields,
      },
      banners_dialog: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Управление баннерами",
      },
      banners_dialog_append: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Добавить баннер",
      },
      clone_banner_form: {},
    };
  },
  methods: {
    ...mapActions([
      "GET_ADMIN_BANNERS",
      "GLOBAL_NOTIFICATIONS",
      "APPEND_BANNER",
      "CHANGE_BANNER",
      "DELETE_BANNER",
    ]),
    async bannerController(action, ...params) {
      let settings = params?.[0];
      if (action == "appendBanner") {
        try {
          if (this.banners_dialog_append.options.visible) {
            if (this.banner_form.fields.inputs.action.value == "") {
              throw {
                title: "Пустое действие",
                text: "Выберите действие при клике на баннер",
                status: "error",
                clean: false,
              };
            }
            if (this.banner_form.fields.inputs.promo_name.value == "") {
              throw {
                title: "Пустое имя",
                text: "Выберите имя баннера (применяемый промокод)",
                status: "error",
                clean: false,
              };
            }
            if (this.banner_form.fields.inputs.promo_picture.value == "") {
              throw {
                title: "Не выбрана картинка",
                text: "Выберите картинку из списка ниже",
                status: "error",
                clean: false,
              };
            }
            await this.APPEND_BANNER(this.banner_form.fields.inputs).then(
              (res) => {
                this.GLOBAL_NOTIFICATIONS(res);
                this.banners_dialog_append.options.visible = false;
                setTimeout(async () => {
                  await this.GET_ADMIN_BANNERS();
                  this.active_banner = this.ADMIN_BANNERS?.[0]?.value;
                }, 500);
              }
            );
          } else this.openDialog(this.banners_dialog_append);

          await this.GET_ADMIN_BANNERS();
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "setBanner") {
        try {
          let newForm = this.banner_form.fields;
          let oldForm = this.clone_banner_form.fields;
          await this.CHANGE_BANNER({ newForm, oldForm }).then((res) => {
            this.banners_dialog.options.visible = false;
            this.GLOBAL_NOTIFICATIONS(res);
            setTimeout(async () => {
              await this.GET_ADMIN_BANNERS();
              this.active_banner = this.ADMIN_BANNERS?.[0]?.value;
            }, 500);
          });
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "deleteBanner") {
        try {
          await this.DELETE_BANNER({ banner: this.activeBanner }).then(
            (res) => {
              this.GLOBAL_NOTIFICATIONS(res);
              // this.$store.state.admin_banners = [];
              setTimeout(async () => {
                await this.GET_ADMIN_BANNERS();
                this.active_banner = this.ADMIN_BANNERS?.[0]?.value;
              }, 500);
            }
          );
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "getBannerLink") {
        try {
          console.log(settings);
          this.banner_form.fields.inputs.promo_picture.value = settings;
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "changeBanner") {
        try {
          if (this.activeBanner == "") {
            throw {
              title: "Баннер не выбран",
              text: "Выберите баннер",
              status: "error",
              clean: false,
            };
          }
          this.openDialog(this.banners_dialog);
          this.banner_form.fields.inputs.promo_name.value = this.activeBanner;
          let bannerData = Array.from(this.ADMIN_BANNERS).filter(
            (item) => item.value == this.activeBanner
          )?.[0];
          this.banner_form.fields.inputs.promo_picture.value =
            bannerData?.image;
          this.banner_form.fields.inputs.location.value = bannerData?.location;
          this.banner_form.fields.inputs.action.value = bannerData?.action;
          this.clone_banner_form = JSON.parse(JSON.stringify(this.banner_form));
          await this.GET_ADMIN_BANNERS();
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
    },
    async closeDialog(data) {
      data.options.visible = false;
    },
    async openDialog(dialog) {
      dialog.options.visible = true;
    },
  },
  computed: {
    ...mapGetters(["ADMIN_BANNERS", "FOLDER_IMAGES"]),
    activeBanner() {
      return this.active_banner;
    },
    filtredBannerImages() {
      let filtredArr = Array.from(this.FOLDER_IMAGES).filter((item) =>
        item.value
          .toLowerCase()
          .includes(this.banner_form.fields.inputs.search.value.toLowerCase())
      );
      if (filtredArr.length) return filtredArr;
      else return this.FOLDER_IMAGES;
    },
  },
  watch: {
    // "banners_dialog_append.options.visible": {
    //   handler(newVal) {
    //     if (newVal == true) {
    //       this.banner_form.fields.inputs.action.attrs.disabled = false;
    //       this.banner_form.fields.inputs.location.attrs.disabled = false;
    //     } else {
    //       this.banner_form.fields.inputs.action.attrs.disabled = true;
    //       this.banner_form.fields.inputs.location.attrs.disabled = true;
    //     }
    //   },
    // },
  },
  async mounted() {
    await this.GET_ADMIN_BANNERS();
    this.active_banner = Array.from(this.ADMIN_BANNERS)?.[0]?.value;
  },
};
</script>
<style lang="scss" scoped>
.banners {
  /* .banners__section */

  &__section {
  }

  /* .banners__controls */

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  /* .banners__delete */

  &__delete {
  }

  /* .banners__control */

  &__control {
    max-width: 24%;
    width: 100%;
    padding: 14px;
    border: 1px solid #ff6800;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
  }

  /* .banners__append */

  &__append {
  }

  /* .banners__list */

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* .banners__item */

  &__item {
  }

  /* .banners__item-image */

  &__item-image {
  }
  /* .banners__dialog-image */

  &__dialog-image {
    max-width: 600px;
    pointer-events: none;
  }
  /* .banner__dialog-action */

  &__dialog-action {
    margin-bottom: 12px;
  }
  /* .banner__dialog-location */

  &__dialog-location {
    margin-bottom: 12px;
  }
  /* .banners__dialog-form */

  &__dialog-form {
  }
  /* .banners__dialog-input */

  &__dialog-input {
    margin-bottom: 12px;
  }
  /* .banners__dialog-images */

  &__dialog-images {
    display: flex;
    overflow-x: auto;
    img {
      max-width: 25%;
      margin-right: 12px;
      padding: 12px;
      cursor: pointer;
      transition: 0.3s ease;
      border-radius: 10px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: $unactive-accent;
      }
    }
  }
}
</style>

<style lang="scss">
.banner__dialog-action {
  margin-bottom: 12px;
}

.banner__dialog-location {
  margin-bottom: 12px;
}

.banners__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 49%;
  min-height: 168px;
  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    height: inherit;
    img {
      margin-top: 12px;
    }
  }
}
.banners__item-image {
  max-width: 100%;
}
</style>
