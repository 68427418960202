const fields = {
  inputs: {
    promo_name: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "name",
        placeholder: "Применяемый промокод",
        label: "Применяемый промокод",
        type: "text",
        always_type: "text",
      },
    },
    promo_picture: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "promo_picture",
        placeholder: "Картинка",
        label: "356x356",
        type: "text",
        always_type: "text",
        hidden: true,
      },
    },
    location: {
      value: "Вверху",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "location",
        placeholder: "Локация",
        label: "Укажите где будет находиться баннер",
        type: "text",
        always_type: "text",
        hidden: true,
      },
    },
    action: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "action",
        placeholder: "Действие",
        label: "Выберите действие",
        type: "text",
        always_type: "text",
        hidden: true,
      },
    },
    description: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "description",
        placeholder: "Текст открывшегося диалога",
        label: "Текст открывшегося диалога",
        type: "text",
        always_type: "text",
      },
    },
    search: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "search",
        placeholder: "Найти картинку по имени",
        label: "Найти картинку по имени",
        type: "text",
        always_type: "text",
      },
    },
  },
  locations: {
    location_header: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "location_header",
        label: "Вверху",
      },
      errorBag: [],
      validate: {},
    },
    location_body: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "location_body",
        label: "Внизу",
      },
      errorBag: [],
      validate: {},
    },
  },
  actions: {
    action_add: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "action_add",
        label: "Редирект + применение",
      },
      errorBag: [],
      validate: {},
    },
    action_null: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "action_null",
        label: "Ничего не происходит",
      },
      errorBag: [],
      validate: {},
    },
    action_dialog: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "action_dialog",
        label: "Открыть диалоговое окно",
      },
      errorBag: [],
      validate: {},
    },
  },
};

export { fields };
